<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <c-card title="위험도별 발굴" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
            <apexchart 
              ref="riskChart" 
              height="260" 
              type="bar"
              :width="riskChart.chartWidth"
              :options="riskChart.chartOptions" 
              :series="riskChart.series">
            </apexchart>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
            <apexchart 
              ref="riskStandardChart" 
              height="260" 
              type="bar"
              :width="riskStandardChart.chartWidth"
              :options="riskStandardChart.chartOptions" 
              :series="riskStandardChart.series">
            </apexchart>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <c-card title="공정별 개선대책 수립 및 실시" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <apexchart 
              ref="processImprChart" 
              height="260" 
              type="bar"
              :width="processImprChart.chartWidth"
              :options="processImprChart.chartOptions" 
              :series="processImprChart.series">
            </apexchart>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fmStatus',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      status: {
        riskChart: [],
        riskStandardChart: [],
        processImprChart: [],
      },
      riskChart: {
        chartOptions: {
          title: {
            text: '위험도별 발굴 건 수'
          },
          chart: {
            type: 'bar',
          },
          colors: [],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return "[위험도] " + val
              }
            }
          },
          yaxis: {
            title: {
              text: '발굴 건 수',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '발굴 건 수',
            data: [],
          },
        ],
        chartWidth: '80%',
      },
      riskStandardChart: {
        chartOptions: {
          title: {
            text: '위험도 기준별 발굴 건 수'
          },
          chart: {
            type: 'bar',
          },
          colors: [],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              // formatter: function (val) {
              //   return "위험도 기준 : " + val
              // }
            }
          },
          yaxis: {
            title: {
              text: '발굴 건 수',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '발굴 건 수',
            data: [],
          },
        ],
        chartWidth: '80%',
      },
      processImprChart: {
        chartOptions: {
          title: {
            text: '공정별 개선대책 수립 및 실시 건 수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return "[공정] " + val
              }
            }
          },
          yaxis: {
            title: {
              text: '개선 건 수',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '진행 건 수',
            data: [],
          },
          {
            name: '지연 건 수',
            data: [],
          },
          {
            name: '완료 건 수',
            data: [],
          },
        ],
        chartWidth: '80%',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.kras.status.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.$_.extend(this.status, _result.data);

        this.setRiskChart();
        this.setRiskStandardChart();
        this.setProcessImprChart();
      },);
    },
    setRiskChart() {
      // 위험도별 발굴
      // risk 	cnt color
      // 1		  2	#b0b0b0
      // 2		  4	#b0b0b0
      // 3		  4	#b0b0b0
      // 4		  9	#ababab
      // 5		  1	#ababab
      // 6		  6	#ababab
      // 8		  0	#ababab
      // 9		  3	#ff8282
      // 10		  1	#ff8282
      // 12		  0	#ff8282
      // 15		  0	#ff7a7a
      // 16		  0	#9c0000
      // 20		  0	#9c0000
      this.$set(this.riskChart.chartOptions, 'colors', this.$_.map(this.status.riskChart, 'color'))
      this.$set(this.riskChart.chartOptions.xaxis, 'categories', this.$_.map(this.status.riskChart, 'risk'))
      this.$set(this.riskChart.series[0], 'data', this.$_.map(this.status.riskChart, 'cnt'))

      this.riskChart.chartWidth = '100%';
      this.$refs['riskChart'].refresh();
    },
    setRiskStandardChart() {
      // 위험도별 발굴
      // riskContents 	          cnt   color
      // 무시할 수 있는 위험(0 ~ 3)	10	  #b0b0b0
      // 미미한 위험(4 ~ 6)	        16	  #ababab
      // 경미한 위험(8 ~ 8)	        0	    #ababab
      // 상당한 위험(9 ~ 12)	      4	    #ff8282
      // 중대한 위험(15 ~ 15)	      0	    #ff7a7a
      // 매우 중대한 위험(16 ~ 20)	0	    #9c0000
      this.$set(this.riskStandardChart.chartOptions, 'colors', this.$_.map(this.status.riskStandardChart, 'color'))
      this.$set(this.riskStandardChart.chartOptions.xaxis, 'categories', this.$_.map(this.status.riskStandardChart, item => {
        return this.$_.split(item.riskContents, '\n')
      }))
      this.$set(this.riskStandardChart.series[0], 'data', this.$_.map(this.status.riskStandardChart, 'cnt'))

      this.riskStandardChart.chartWidth = '100%';
      this.$refs['riskStandardChart'].refresh();
    },
    setProcessImprChart() {
      // 공정별 개선대책 수립 및 실시
      // processName	cntIng	cntInaction	cntComplete
      // RM-T3 공정	  0	      0	          0
      // 건조-탈수	  0	      0	          0
      // this.$set(this.processImprChart.chartOptions, 'colors', this.$_.map(this.status.processImprChart, 'color'))
      this.$set(this.processImprChart.chartOptions.xaxis, 'categories', this.$_.map(this.status.processImprChart, 'processName'))
      let series = [
        {
          name: '진행 건 수',
          data: this.$_.map(this.status.processImprChart, 'cntIng'),
        },
        {
          name: '완료 건 수',
          data: this.$_.map(this.status.processImprChart, 'cntComplete'),
        },
        {
          name: '지연 건 수',
          data: this.$_.map(this.status.processImprChart, 'cntInaction'),
        }
      ];
      this.$set(this.processImprChart, 'series', series)

      this.processImprChart.chartWidth = '100%';
      this.$refs['processImprChart'].refresh();
    },
  }
};
</script>